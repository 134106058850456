<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="1000"
    >
        <LoadData ref="load"></LoadData>
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                {{ titleDialog  }}
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation class="mt-4">
                    <v-row class="justify-center mb-5">
                        <v-col cols="12" class="pb-0 pt-0 mt-5">
                            <text-field-component label="Nombre encuesta" ref="tfNombreEncuesta" :valor="nombreEncuesta" required :disabled="!mostrarBtnGuardarEncuesta"></text-field-component>
                        </v-col>
                        <v-col cols="12" class="pb-0 pt-0 mt-5 text-right" v-if="mostrarBtnGuardarEncuesta">
                            <v-btn
                                color="primary"
                                text
                                @click="guardarEncuesta()"
                            >
                                Guardar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                <template v-if="mostrarPreguntas">
                    <v-form ref="formPreguntas" v-model="validPreguntas" lazy-validation class="mt-4">
                        <v-divider class="mt-5 mb-5"></v-divider>
                        <v-row class="justify-center">
                            <v-col cols="12" class="pb-0 pt-0 mt-5">
                                <text-field-component label="Pregunta" ref="tfPregunta" :valor="pregunta" required></text-field-component>
                            </v-col>
                            <v-col cols="12" class="pb-0 pt-0 mt-5">
                                <v-select  
                                    v-model="tipoRespuesta" 
                                    :items="tipos"
                                    outlined 
                                    :dense="true"
                                    label="Tipo respuesta"
                                    item-text="text"
                                    item-value="id"
                                    @change="cambioTipoRespuesta"
                                />
                            </v-col>
                            <v-col cols="12" class="pb-0 pt-0" v-if="mostrarOpciones">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>
                                                    opciones
                                                </th>
                                                <th>
                                                    Acciones
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(opcion, index) in opciones">
                                                <td>
                                                    <v-text-field
                                                        class="mt-5"
                                                        dense
                                                        v-model="opciones[index].texto"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-btn
                                                        fab
                                                        dark
                                                        color="red"
                                                        small
                                                        @click="eliminarOpcion(opcion, index)"
                                                    >
                                                        <v-icon dark>
                                                            mdi-minus
                                                        </v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                                <div class="text-right">
                                        <v-btn
                                            dark
                                            color="primary"
                                            small
                                            @click="agregarOpcion"
                                        >
                                            Agregar opción
                                        </v-btn>
                                    </div>
                            </v-col>
                            <v-col cols="12" class="pb-0 pt-0 mt-5 text-right mb-5">
                                <v-btn
                                    dark
                                    color="green"
                                    @click="guardarPregunta"
                                >
                                    Crear Pregunta
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    <table-component
                        :fields="fields"
                        :key="reloadPreguntas"
                        :readOnly="true"
                        :altoTabla="400"
                        :currentPage="currentPage" 
                        :params="payload"
                        :paginable="true"
                        view="encuesta-pregunta"
                    ></table-component>
                </template>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="close"
                >
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import axios from 'axios'
    import LoadData from '@/components/LoadData.vue'
    import { EventBus } from "@/event/event"

    export default {
        name: 'encuestaForm',
        components: {
            LoadData,
        },
        data() {
            return {
                titleDialog: '',
                dialog: false,
                nombreEncuesta: '',
                valid: true,
                validPreguntas: true,
                mostrarPreguntas: false,
                mostrarBtnGuardarEncuesta: true,
                pregunta: '',
                tipos: [
                    {id: 'A', text: 'Area de texto'},
                    {id: 'T', text: 'Texto'},
                    {id: 'X', text: 'Si/No'},
                    {id: 'S', text: 'Selección'}
                ],
                tipoRespuesta: 'A',
                mostrarOpciones: false,
                encuestaId: null,
                fields: [
                    { key: 'pregunta', label: 'Pregunta'},
                    { key: 'tipo_respuesta', label: 'Tipo respuesta',},
                    { key: 'opciones', label: 'Opciones'},
                    { key: 'fecha', label: 'Fecha'},
                    { key: 'actions', label: 'Acción', width: '100px' }
                ],
                reloadPreguntas: 0,
                currentPage: '',
                payload: '',
                opciones: [
                    {texto: 'Opcion 1'},
                    {texto: 'Opcion 2'}
                ],
            }
        },
        methods: {
            open(item) {
                this.dialog = true
                this.tipoRespuesta = 'A'
                this.titleDialog = 'Encuesta nueva'
                if (item) {
                    this.mostrarBtnGuardarEncuesta = false
                    this.encuestaId = item.id
                    this.$nextTick(() => {
                        this.$refs.tfNombreEncuesta.setValue(item.nombre)
                    })
                    this.getPreguntas()
                    this.mostrarPreguntas = true
                    this.reloadPreguntas++
                }
            },
            close() {
                this.$refs.form.reset()
                this.mostrarBtnGuardarEncuesta = true
                this.mostrarPreguntas = false
                this.dialog = false
            },
            async guardarEncuesta() {
                if (this.$refs.form.validate()) {
                    this.$refs.load.open();
                    const params = {
                        user_id: localStorage.getItem('user_id'),
                        nombre: this.$refs.tfNombreEncuesta.getValue(),
                    }
                    await axios.post(process.env.VUE_APP_BACKEND_URL + "/administracion/encuesta/crear/", params).then((response) => {
                        this.mostrarBtnGuardarEncuesta = false
                        this.mostrarPreguntas = true
                        this.encuestaId = response.data.id
                        EventBus.$emit('reload-encuestas', response)
                        this.getPreguntas()
                        this.$refs.load.close();
                    }).catch(error => {
                        console.error(error)
                        this.$refs.load.close();
                    })
                }
            },
            async guardarPregunta() {
                if (this.$refs.formPreguntas.validate()) {
                    this.$refs.load.open();
                    let opcionAux = ''
                    if (this.tipoRespuesta == 'S') {
                        opcionAux = this.opciones.map(element => element.texto).join(';')
                    }
                    const params = {
                        encuesta_id: this.encuestaId,
                        user_id: localStorage.getItem('user_id'),
                        pregunta: this.$refs.tfPregunta.getValue(),
                        tipo_respuesta: this.tipoRespuesta,
                        opciones: opcionAux
                    }
                    await axios.post(process.env.VUE_APP_BACKEND_URL + "/administracion/encuesta/pregunta/crear/", params).then((response) => {
                        this.$refs.formPreguntas.reset()
                        this.tipoRespuesta = 'A'
                        this.opciones = [{texto: 'Opcion 1'}, {texto: 'Opcion 2'}]
                        this.getPreguntas()
                        this.reloadPreguntas++
                        this.$refs.load.close();
                    }).catch(error => {
                        console.error(error)
                        this.$refs.load.close();
                    })
                    /* this.getPreguntas() */
                }
            },
            cambioTipoRespuesta() {
                if (this.tipoRespuesta == 'S') {
                    this.mostrarOpciones = true
                } else {
                    this.mostrarOpciones = false
                }
            },
            getPreguntas() {
                this.currentPage = process.env.VUE_APP_BACKEND_URL + "/administracion/encuesta/pregunta/" + this.encuestaId + "?page="
            },
            agregarOpcion() {
                if (this.opciones.length < 10) {
                    this.opciones.push({ texto: ''})
                }
            },
            eliminarOpcion(valor, index){
                if (this.opciones.length > 2) {
                    this.opciones.splice(index, 1)
                }
            },
        }
    }
</script>