import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import "./axios";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

import GmapVue from 'gmap-vue'
import VueGtag from 'vue-gtag'

import TextField from './components/TextField.vue'
import ComboBoxComponent from './components/ComboBoxComponent.vue'
import TablaComponent from '@/shared/TablaComponent.vue'

Vue.config.productionTip = false

Chart.register(ChartDataLabels);

Vue.component('text-field-component', TextField);
Vue.component('combo-component', ComboBoxComponent);
Vue.component('table-component', TablaComponent);
Vue.use(VueSweetalert2);
new Vue({
  router,
  VueChartkick,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyCnPGGCAXl3g1pC3Qcig2CUaKUCukWjtTE',
    libraries: 'places',
  },
  autoBindAllEvents: false,
  installComponents: true,
  dynamicLoad: false,
})

Vue.use(VueGtag, {
  config: { id: "G-YY3LVZ3PR1"}
})
